import request from '@/utils/request'
import { apiBaseUrl } from '@/utils/global'
export default {
  getJsSdkUiPackage(url) {
    return request({
      url: apiBaseUrl + '/app/common/getjssdkuipackage',
      method: 'get',
      params: { url }
    })
  },
}
