import axios from 'axios'
// import Qs from 'qs'
import { apiKey, apiKeyName, tokenKey, clientFrame, clientFramekey } from './global'

axios.defaults.headers[tokenKey] = ''
axios.defaults.headers[apiKeyName] = apiKey
axios.defaults.headers[clientFramekey] = clientFrame
axios.defaults.headers['edomain'] = location.pathname
// create an axios instance
const service = axios.create({
  //  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  //  withCredentials: true, // send cookies when cross-domain requests
  //  timeout: 5000 // request timeout
})
// service.defaults.headers.delete["Content-Type"] = "application/x-www-form-urlencoded"
/* service.defaults.transformRequest = [(data) => { return Qs.stringify(data) }]

service.defaults.paramsSerializer = (params) => {
	return Qs.stringify(params)
}*/
// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      return { code: 501, message: res.message, data: false }
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    return { code: 501, message: error.message, data: false }
    // return Promise.reject(error)
  }
)

export default service
