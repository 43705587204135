<template>
  <div class="page">
    <div class="container">
      <div class="btn-group" @click="launch" v-if="wechatState">
        <wx-open-launch-app
          id="launch-btn"
          @error="handleError"
          @launch="handleLaunch"
          :extinfo="dataStr"
         appid="wx8350428f3d5ce1e6"
        >
          <script type="text/wxtag-template">
            <style>
              .btn {
                padding: 12px;
              }
              .download {
                color: #ffffff;
                background: #2b303c;
                width:100%;
                height:100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                border-radius: 2px;
              }
            </style>
            <button class="download btn" style="height:50px;width:100%;border:none;">广新App唤起测试</button>
          </script>
        </wx-open-launch-app>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import wx from "weixin-js-sdk"
 import api from '@/api/common/index'
export default {
  data() {
    return {
      wechatState: false, // 是否显示微信打开app功能按钮
      openAppState: false, //  显示打开app 的按钮
      dataStr:'',
    };
  },
  methods: {
    closeTip() {},
    openUrl() {
      this.judgePhoneType();
    },
    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // 微信内
      if (isWeixin) {
        Dialog.alert({ message: "微信浏览器" });
      } else {
        if (isAndroid) this.openInstallHandle("android"); //android端
        if (isIOS) this.openInstallHandle("ios"); //ios端
      }
    },
 
    launch() {
      if (!this.wechatState) {
        Dialog.alert({ message: "不支持标签降级处理" });
        return;
      }
    },
 
    openInstallHandle(type) {
      console.log(type)
    },
 
    judgeNavigator() {
      // 微信版本号大于 7.0.12 开放标签才可进行 唤醒 app 跳转
      console.log(navigator.userAgent)
      const wechatInfo =navigator.userAgent.match(/MicroMessenger\/([\\d\\.]+)/i);//navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)//window.navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i);
      if (!wechatInfo) return;
      let judgewechat = wechatInfo[1].split(".");
      let flag = false;
      //   Dialog.alert({ message: "调用结果" + JSON.stringify(wechatInfo) });
      if (judgewechat[0] >= 7) {
        if (judgewechat[1] >= 0) {
          if (judgewechat[2] >= 12) {
            this.wechatState = true;
            console.log("当前符合 h5 打开指定app");
            flag = true;
          }
        }
      }
      if (!flag) Dialog.alert({ message: "请升级当前微信版本" });
    },
 
    handleError(e) {
      console.log("error", e, e.detail);
      // 跳到下载download.html页面
      // Dialog.alert({ message: "error:" + JSON.stringify(e.detail) });
    },
 
    handleLaunch(e) {
      console.log("success", e, e.detail);
      // Dialog.alert({ message: "success:" + JSON.stringify(e) });
    },
 
    launchBtnInit() {
      // 获取 homelist 组件传递过来的打开app的 显示状态
      var btn = document.getElementById("launch-btn");
      if (!btn) return;
      btn.addEventListener("launch", function (e) {
        console.log("success",e,e.detail);
        Dialog.alert({ message: "success:" + JSON.stringify(e.detail) });
      });
      btn.addEventListener("error", function (e) {
        console.log("error",e,e.detail);
        if (e.isTrusted == false) {
					// alert("跳转失败")
					window.open("https://www.baidu.com")
				}
        Dialog.alert({ message: "error:" + JSON.stringify(e.detail) });
      });
    },
 
    wechatInit() {
      api.getJsSdkUiPackage(window.location.href)
        .then((res) => {
          if (res.data) {
            this.wechatConfig(res.data);
          } else {
            console.log("获取微信签名失败");
          }
        });
    },
 
    wechatConfig(config) {
      let that = this;
      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名，见附录1
        jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList: ["wx-open-launch-app"], // 获取开放标签权限
      });
      wx.error(function (err) {
        console.log("错误：", err);
        Dialog.alert({ message: "错误:" + JSON.stringify(err) });
      });
      wx.ready(function () {
        console.log("微信签名成功");
        Dialog.alert({ message: "微信签名成功" });
        // that.launchBtnInit(); // 外部浏览器唤起app
        that.wechatState = true;
      });
    },
  },
 
  computed: {},
 
  mounted() {
    this.judgeNavigator();
    this.wechatInit(); // 微信标签唤起app
  },
  created() {},
  onShow() {},
};
</script>

<style lang="less" scoped>
.page {
  overflow: auto;
  height: 100vh;
  background: #fff;
  .container {
    padding-top: 80px;
    .result-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 128px;
      margin-top: 113px;
      .status-icon {
        height: 50px;
        width: 50px;
        margin-bottom: 10px;
      }
      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2b303c;
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      width: 320px;
      height: 44px;
      border: 1px solid #eee;
      position: relative;
      .search {
        color: #2b303c;
      }
    }
  }
}
#launch-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>