/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */
var apiKey = process.env.NODE_ENV === 'development' ? 'C9F71118B2FA1B1D84D3646A947830F2' : 'C9F71118B2FA1B1D84D3646A947830F2'
var apiKeyName = 'kpcn-key'
var tokenKey = 'kpcn-token-a'
var clientFrame = '1'
var clientFramekey = 'kpcn-cf'
var apiVersion = '1'
var apiBaseUrl = '/api/v' + apiVersion // '/v' + apiVersion //
var uploadImageUrl = `${apiBaseUrl}/common/uploadimage`
var uploadVideoUrl = `${apiBaseUrl}/common/uploadvideo`
var imageUrl = `${process.env.NODE_ENV === 'development' ? apiBaseUrl : 'http://api.hzynsy.com/v1'}/common/thumbnail`
var snapshotUrl = `${apiBaseUrl}/common/snapshot`
var imageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/x-icon']
var videoTypes = ['video/mp4']
var rightTypes = [{ id: 1, name: '送优惠券', icon: 'icon-youhuiquan' }, { id: 2, name: '消费打折', icon: 'icon-icon_zhekou' }, { id: 3, name: '运费打折', icon: 'icon-yunfei' }, { id: 4, name: '积分翻倍', icon: 'icon-jifen2' }, { id: 5, name: '综合礼包', icon: 'icon-libao' }, { id: 6, name: '利润分红', icon: 'icon-yly_qianzhuang' }]
var authorityKey = {
  商品管理: 11001,
  分类管理: 11002,
  类型管理: 11003,
  商品评论管理: 11005,
  订单列表: 12001,
  售后订单: 12002,
  用户管理: 13011,
  用户分组: 13012,
  用户标签: 13013,
  用户等级: 13014,
  会员类型: 13021,
  会员权益: 13022,
  会员协议: 13023,
  会员记录: 13024,
  系统设置: 17001,
  员工管理: 17002,
  角色管理: 17003,
  操作日志: 17004,
  区域管理: 17005,
  消息设置: 17006,
  分销员: 16011,
  分销设置: 16012,
  分销等级: 16013,
  优惠券: 16021,
  领取记录: 16022,
  分红记录: 16031,
  月报表: 16033,
  充值活动: 16041,
  会员充值记录: 16042,
  商户管理: 17011,
  商户类型: 17012,
  内容管理: 17009,
  提现申请: 15001,
  充值记录: 15002,
  资金记录: 15003,
  佣金记录: 15004,
  数据看板: 10001,
  商品统计: 10002,
  用户统计: 10003,
  交易统计: 10004

}
export { apiBaseUrl, apiVersion, apiKey, apiKeyName, tokenKey, clientFrame, clientFramekey, rightTypes, authorityKey, uploadImageUrl, uploadVideoUrl, imageUrl, imageTypes, videoTypes, snapshotUrl }
